<template>
  <div >
    <div class="flip-clock">
      <div v-if="timerFinish">
        <p class="finish-auction">The auction is over.</p>
      </div>
      <div v-else>
        <div v-for="(tracker,  index ) in trackers" :key="index">
          <Tracker
              :property="tracker"
              :time="time"
              :seconds="time.Seconds"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import Tracker from "./Tracker.vue";
  import moment from "moment"
  export default {
    emits: ['timeUpdate'],
    name: 'Timer',
    props: ['date','finish','callback', 'auctionFinish'],
    components: {
      Tracker
    },
    computed: {
      zerofill () {
        return 0
      }
    },
    data() {
      return {
        current: 0,
        previous: 0,
        show: false,
        timerFinish: false,
        time: {
          Years: '',
          Days: '',
          Hours: '',
          Minutes: '',
          Seconds: ''
        },
        i: 0,
        trackers: ['Years', 'Days', 'Hours','Minutes','Seconds']
      }
    },
    mounted (){
      this.setCountdown(this.date)
      if ( window['cancelAnimationFrame'] ) {
        cancelAnimationFrame(this.frame);
      }
      if (window['requestAnimationFrame']) {
        this.setCountdown(this.date);
        this.update();
      }
    },
    watch: {
      date (newVal){
        this.setCountdown(newVal);
      }
    },
    methods: {
      setCountdown(date){
        if ( date ) {
          this.countdown = moment(date, 'YYYY-MM-DD HH:mm:ss');
        } else {
          this.countdown = moment().endOf('day');
        }
      },
      update() {
        this.frame = requestAnimationFrame(this.update.bind(this));
        if ( this.i++ % 10 ) { return; }
        let t = moment(new Date());
        if ( this.countdown ) {
          this.time.Years = this.countdown.diff(t, 'years') && this.countdown.diff(t, 'years') > 0 ? Math.floor(this.countdown.diff(t, 'years')) : undefined;
          this.time.Days = this.countdown.diff(t, 'days') && this.countdown.diff(t, 'days') > 0 ? Math.floor(this.countdown.diff(t, 'days') % 365.25) : undefined;
          this.time.Hours = this.countdown.diff(t, 'hours') && this.countdown.diff(t, 'hours') > 0 ? Math.floor(this.countdown.diff(t, 'hours') % 24) : undefined;
          this.time.Minutes = this.countdown.diff(t, 'minutes') && this.countdown.diff(t, 'minutes') > 0 ? Math.floor(this.countdown.diff(t, 'minutes') % 60) : undefined;
          this.time.Seconds = this.countdown.diff(t, 'seconds') && this.countdown.diff(t, 'seconds') > 0 ? Math.floor(this.countdown.diff(t, 'seconds') % 60) : undefined;
        }
        if (this.countdown.diff(t) && this.countdown.diff(t) > 0) {
          this.timerFinish = false
        } else {
          this.timerFinish = true
          if (!this.finish) {
            this.$emit('auctionFinish', true)
          }
        }
        this.time.Total = t;
        return this.time;
      }
    }
  };
</script>

<style lang="scss">
  .finish-auction {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    margin: 15px 0;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #C2C2C2;
    @media (max-width: 768px) {
      margin: 16px 0;
    }
  }
  .flip-clock {
    div {
      display: flex;
      justify-content: center;
      text-align: center;
      perspective: 600px;
      *,
      *:before,
      *:after { box-sizing: border-box; }
    }
  }

  .flip-clock__piece {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /*display: inline-block;*/
    margin: 0 0.2vw;

    @media (min-width: 1000px) {
      margin: 0 5px;
    }
  }

  .flip-clock__slot {
    font-size: 1rem;
    line-height: 1.5;
    display: block;
  }

  .flip-card {
    display: block;
    position: relative;
    padding-bottom: 0.72em;
    font-size: 26px;
    line-height: 30px;
  }

  @media (min-width: 1000px) {
    .flip-clock__slot {
      margin-top: 9px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #B1B1B1;
    }
    .flip-card {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 34px;
      text-align: center;
      color: #363636;
    }
  }


  /*////////////////////////////////////////*/


  .flip-card__top,
  .flip-card__bottom,
  .flip-card__back-bottom,
  .flip-card__back::before,
  .flip-card__back::after {
    background: #E2E2E2;
    border-radius: 5px 5px 0px 0px;
    display: block;
    height: 0.72em;
    color: #363636;
    padding: 0.23em 0.25em 0.4em;
    backface-visiblity: hidden;
    transform-style: preserve-3d;
    width: 53px;
  }

  .flip-card__bottom,
  .flip-card__back-bottom {
    position: absolute;
    top: 50%;
    left: 0;
    border-top: solid 1px #000;
    border-radius: 0 0 0.15em 0.15em;
    pointer-events: none;
    overflow: hidden;
    z-index: 2;
  }

  .flip-card__back-bottom {
    z-index: 1;
  }

  .flip-card__bottom::after,
  .flip-card__back-bottom::after {
    display: block;
    margin-top: -0.72em;
  }

  .flip-card__back::before,
  .flip-card__bottom::after,
  .flip-card__back-bottom::after {
    content: attr(data-value);
  }

  .flip-card__back {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0%;
    pointer-events: none;
  }

  .flip-card__back::before {
    position: relative;
    overflow: hidden;
    z-index: -1;
  }

  .flip .flip-card__back::before {
    z-index: 1;
    animation: flipTop 0.3s cubic-bezier(.37,.01,.94,.35);
    animation-fill-mode: both;
    transform-origin: center bottom;
  }

  .flip .flip-card__bottom {
    transform-origin: center top;
    animation-fill-mode: both;
    animation: flipBottom 0.6s cubic-bezier(.15,.45,.28,1);// 0.3s;
  }

  @keyframes flipTop {
    0% {
      transform: rotateX(0deg);
      z-index: 2;
    }
    0%, 99% {
      opacity: 1;
    }
    100% {
      transform: rotateX(-90deg);
      opacity: 0;
    }
  }

  @keyframes flipBottom {
    0%, 50% {
      z-index: -1;
      transform: rotateX(90deg);
      opacity: 0;
    }
    51% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
      z-index: 5;
    }
  }

</style>

