var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flip-clock"
  }, [_vm.timerFinish ? _c('div', [_c('p', {
    staticClass: "finish-auction"
  }, [_vm._v("The auction is over.")])]) : _c('div', _vm._l(_vm.trackers, function (tracker, index) {
    return _c('div', {
      key: index
    }, [_c('Tracker', {
      attrs: {
        "property": tracker,
        "time": _vm.time,
        "seconds": _vm.time.Seconds
      }
    })], 1);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }