var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    ref: "el",
    staticClass: "flip-clock__piece",
    attrs: {
      "id": _vm.property
    }
  }, [_c('span', {
    staticClass: "flip-clock__card flip-card"
  }, [_c('b', {
    staticClass: "flip-card__top"
  }, [_vm._v(_vm._s(_vm.current))]), _c('b', {
    staticClass: "flip-card__bottom",
    attrs: {
      "data-value": _vm.current
    }
  }), _c('b', {
    staticClass: "flip-card__back",
    attrs: {
      "data-value": _vm.previous
    }
  }), _c('b', {
    staticClass: "flip-card__back-bottom",
    attrs: {
      "data-value": _vm.previous
    }
  })]), _c('span', {
    staticClass: "flip-clock__slot"
  }, [_vm._v(_vm._s(_vm.$t('main.' + _vm.property.toLowerCase())))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }