<template>
  <div class="preloader">
    <header-loading/>
    <div class="preloader-bg ">
      <vue-skeleton-loader
        type="rect"
        :width="438"
        :height="21"
        :rounded="true"
        class="mt-6"
        :radius="5"
        animation="wave"
      />
      <vue-skeleton-loader
        type="rect"
        :height="176"
        class="w-full"
        animation="wave"
      />
    </div>
    <div class="preloader-main">
      <div class="preloader-main-header">
        <vue-skeleton-loader
          type="rect"
          :width="165"
          :height="50"
          :rounded="true"
          class="mt-6"
          :radius="5"
          animation="wave"
        />
        <div class="flex">
          <vue-skeleton-loader
            type="rect"
            :width="150"
            :height="26"
            :rounded="true"
            class="mr-2"
            :radius="5"
            animation="wave"
          />
          <vue-skeleton-loader
            type="rect"
            :width="150"
            :height="26"
            :rounded="true"
            class="mt-6"
            :radius="5"
            animation="wave"
          />
        </div>
      </div>
      <div class="flex justify-between w-full mt-37">
        <div class="flex">
          <vue-skeleton-loader
            v-for="i in 5"
            :key="i"
            type="rect"
            :width="71"
            :height="41"
            :rounded="true"
            class="mr-2"
            :radius="1000"
            animation="wave"
          />
        </div>
        <div class="flex">
          <vue-skeleton-loader
            v-for="i in 2"
            :key="i"
            type="rect"
            :width="210"
            :height="41"
            :rounded="true"
            class="mr-2"
            :radius="1000"
            animation="wave"
          />
        </div>
      </div>
      <div class="preloader-main-items">
       <div v-for="i in 3" :key="i" class="preloader-main-items-item">
         <vue-skeleton-loader
           type="rect"
           :width="350"
           :height="304"
           :rounded="true"
           :radius="20"
           animation="wave"
         />
         <vue-skeleton-loader
           type="rect"
           :width="198"
           :height="30"
           :rounded="true"
           class="block"
           :radius="5"
           animation="wave"
         />
         <vue-skeleton-loader
           type="rect"
           :width="160"
           :height="45"
           :rounded="true"
           class="block"
           :radius="5"
           animation="wave"
         />
       </div>
     </div>
    </div>
  </div>
</template>

<script>
  import VueSkeletonLoader from 'skeleton-loader-vue';
  import HeaderLoading from "./HeaderLoading";
  export default {
    name: 'ExploreLoading',
    props: ['status'],
    components: {HeaderLoading, VueSkeletonLoader},
    mounted() {
      document.body.style.overflowY = 'hidden'
      document.body.style.width = '99%'
    },
    beforeDestroy () {
      document.body.style.overflowY = 'auto'
      document.body.style.width = '100%'
    },
  }
</script>
<style scoped lang="scss">


  body, html, .preloader {
    z-index: 9999;
    background: #F5F5F5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;

    .w-full {
      width: 100%;
    }

    .mt-37 {
      margin-top: 37px;
    }

    .justify-between {
      justify-content: space-between;
    }

    .flex {
      display: flex;
    }

    .mr-2 {
      margin-right: 8px;
    }

    .mr-30 {
      margin-right: 30px;
    }

    &-bg {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 5px;

      .w-full {
        margin-top: 6px;
        width: 100% !important;
      }

    }

    &-text {
      display: flex;
      justify-content: center;
      padding: 5px;
    }

    &-main {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #e8e8e8;
      padding: 48px 18% 184px;

      @media (max-width: 768px) {
        padding: 48px 20px 184px;
      }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      &-text {
        margin-top: 18px;
      }

      &-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 47px;
        width: 100%;
        flex-wrap: wrap;

        &-item {
          border-radius: 20px;
          /*margin: 20px 22px;*/
          background: #e2e2e2;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 20px;

          .block {
            margin-top: 10px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;

            &-item {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              margin: 10px;

              .mt-10 {
                margin-top: 10px;
              }
            }
          }
        }
      }

    }
  }

</style>
