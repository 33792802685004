<template>
	<div>
		<explore-loading v-if="loading"/>
		<div>
			<div class="header-info explore">
				<div class="wrapper">
					<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
				</div>
			</div>
			<div class="explore">
				<img src="../../public/explore/spring_p.webp" alt="Explore" class="explore-image">
				<div class="wrapper">
					<div class="explore-header">
						<h1 class="explore-header__title">Explore</h1>
					</div>
					<div class="explore-params">
						<div class="explore-params__tabs">
							<div class="explore-params__tab" :class="activeTab === 'all' ? 'active' : ''" @click="changeTab('all')">
								All
							</div>
							<div class="explore-params__tab" :class="activeTab === 'Winter' ? 'active' : ''" @click="changeTab('Winter')">Winter
							</div>
							<div class="explore-params__tab" :class="activeTab === 'Spring' ? 'active' : ''" @click="changeTab('Spring')">Spring
							</div>
							<div class="explore-params__tab" :class="activeTab === 'Summer' ? 'active' : ''" @click="changeTab('Summer')">Summer
							</div>
							<div class="explore-params__tab" :class="activeTab === 'Autumn' ? 'active' : ''" @click="changeTab('Autumn')">Autumn
							</div>
							<div class="select-dbox">
								<div class="select-dbox__current" tabindex="1">
									<div class="select-dbox__value">
										<input class="select-dbox__input" type="radio" id="all-seasons" value="all-seasons" name="option-seasons" checked="checked"/>
										<p class="select-dbox__input-text filter">All</p>
									</div>
									<div class="select-dbox__value">
										<input class="select-dbox__input" type="radio" id="winter" value="winter" name="option-seasons"/>
										<p class="select-dbox__input-text filter">Winter</p>
									</div>
									<div class="select-dbox__value">
										<input class="select-dbox__input" type="radio" id="spring" value="spring" name="option-seasons"/>
										<p class="select-dbox__input-text filter">Spring</p>
									</div>
									<div class="select-dbox__value">
										<input class="select-dbox__input" type="radio" id="summer" value="summer" name="option-seasons"/>
										<p class="select-dbox__input-text filter">Summer</p>
									</div>
									<div class="select-dbox__value">
										<input class="select-dbox__input" type="radio" id="autumn" value="autumn" name="option-seasons"/>
										<p class="select-dbox__input-text filter">Autumn</p>
									</div>
									<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M9.10044 1.2671C9.10311 1.1632 9.06797 1.06117 8.99986 0.975189C8.93174 0.889211 8.83402 0.82356 8.72036 0.787364C8.60671 0.751167 8.48273 0.746224 8.36565 0.77322C8.24856 0.800217 8.14425 0.857812 8.06711 0.937998L5.00918 3.99757L1.95232 0.937998C1.90475 0.882529 1.84437 0.836075 1.77489 0.801545C1.70541 0.767015 1.62834 0.745154 1.54855 0.737329C1.46876 0.729504 1.38796 0.735885 1.31118 0.756072C1.2344 0.77626 1.16328 0.809818 1.10233 0.854646C1.04138 0.899473 0.991941 0.954605 0.957001 1.01659C0.92206 1.07857 0.902435 1.14607 0.89934 1.21486C0.896243 1.28365 0.909704 1.35224 0.938969 1.41635C0.968233 1.48046 1.01262 1.53869 1.06936 1.58741L4.56598 5.09034C4.62075 5.14535 4.68873 5.18952 4.76534 5.21981C4.84196 5.2501 4.92534 5.26579 5.00972 5.26579C5.09409 5.26579 5.17747 5.2501 5.25409 5.21981C5.3307 5.18952 5.39874 5.14535 5.45351 5.09034L8.9535 1.58741C9.0452 1.49892 9.09733 1.38542 9.10058 1.2671L9.10044 1.2671Z"
											fill="black"/>
									</svg>
								</div>
								<ul class="select-dbox__list">
									<li>
										<label class="select-dbox__option" for="all-seasons" @click="changeTab('all')" aria-hidden="aria-hidden">All</label></li>
									<li>
										<label class="select-dbox__option" for="winter" @click="changeTab('Winter')" aria-hidden="aria-hidden">Winter</label></li>
									<li>
										<label class="select-dbox__option" for="spring" @click="changeTab('Spring')" aria-hidden="aria-hidden">Spring</label></li>
									<li>
										<label class="select-dbox__option" for="summer" @click="changeTab('Summer')" aria-hidden="aria-hidden">Summer</label></li>
									<li>
										<label class="select-dbox__option" for="autumn" @click="changeTab('Autumn')" aria-hidden="aria-hidden">Autumn</label></li>
								</ul>
							</div>
						</div>
						<div class="explore-params__right">
							<div class="explore-params__filter">
								<div class="select-box">
									<div class="select-box__current" tabindex="1">
										<div class="select-box__value">
											<input class="select-box__input" type="radio" id="default-filter" value="default-filter" name="option-filter" checked="checked"/>
											<p class="select-box__input-text filter">Filter</p>
										</div>
										<div class="select-box__value">
											<input class="select-box__input" type="radio" id="all" value="all" name="option-filter"/>
											<p class="select-box__input-text filter">All</p>
										</div>
										<div class="select-box__value">
											<input class="select-box__input" type="radio" id="1year" value="1year" name="option-filter"/>
											<p class="select-box__input-text filter">1 Year</p>
										</div>
										<div class="select-box__value">
											<input class="select-box__input" type="radio" id="10years" value="10years" name="option-filter"/>
											<p class="select-box__input-text filter">10 Years</p>
										</div>
										<div class="select-box__value">
											<input class="select-box__input" type="radio" id="100years" value="100years" name="option-filter"/>
											<p class="select-box__input-text filter">100 Years</p>
										</div>
										<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M9.10044 1.2671C9.10311 1.1632 9.06797 1.06117 8.99986 0.975189C8.93174 0.889211 8.83402 0.82356 8.72036 0.787364C8.60671 0.751167 8.48273 0.746224 8.36565 0.77322C8.24856 0.800217 8.14425 0.857812 8.06711 0.937998L5.00918 3.99757L1.95232 0.937998C1.90475 0.882529 1.84437 0.836075 1.77489 0.801545C1.70541 0.767015 1.62834 0.745154 1.54855 0.737329C1.46876 0.729504 1.38796 0.735885 1.31118 0.756072C1.2344 0.77626 1.16328 0.809818 1.10233 0.854646C1.04138 0.899473 0.991941 0.954605 0.957001 1.01659C0.92206 1.07857 0.902435 1.14607 0.89934 1.21486C0.896243 1.28365 0.909704 1.35224 0.938969 1.41635C0.968233 1.48046 1.01262 1.53869 1.06936 1.58741L4.56598 5.09034C4.62075 5.14535 4.68873 5.18952 4.76534 5.21981C4.84196 5.2501 4.92534 5.26579 5.00972 5.26579C5.09409 5.26579 5.17747 5.2501 5.25409 5.21981C5.3307 5.18952 5.39874 5.14535 5.45351 5.09034L8.9535 1.58741C9.0452 1.49892 9.09733 1.38542 9.10058 1.2671L9.10044 1.2671Z"
												fill="black"/>
										</svg>
									</div>
									<ul class="select-box__list">
										<li><label class="select-box__option" for="default-filter" aria-hidden="aria-hidden">Filter</label>
										</li>
										<li>
											<label class="select-box__option" for="all" @click="changeFilter('all')" aria-hidden="aria-hidden">All</label>
										</li>
										<li>
											<label class="select-box__option" for="1year" @click="changeFilter('1 year')" aria-hidden="aria-hidden">1 Year</label></li>
										<li>
											<label class="select-box__option" for="10years" @click="changeFilter('10 years')" aria-hidden="aria-hidden">10 Years</label>
										</li>
										<li>
											<label class="select-box__option" for="100years" @click="changeFilter('100 years')" aria-hidden="aria-hidden">100 Years</label>
										</li>
									</ul>
								</div>
							</div>
							<div class="explore-params__sort">
								<div class="select-custom">
									<div class="select-custom__current" tabindex="1">
										<div class="select-custom__value">
											<input class="select-custom__input" type="radio" id="default-sort" value="default-sort" name="option-sort" checked="checked"/>
											<p class="select-custom__input-text sort">Sort by</p>
										</div>
										<!--                    <div class="select-custom__value"><input class="select-custom__input" type="radio" id="recently-listed" value="recently-listed" name="option-sort" />-->
										<!--                      <p class="select-custom__input-text sort">Recently listed</p>-->
										<!--                    </div>-->
										<!--                    <div class="select-custom__value"><input class="select-custom__input" type="radio" id="newest" value="newest" name="option-sort" />-->
										<!--                      <p class="select-custom__input-text sort">Newest</p>-->
										<!--                    </div>-->
										<div class="select-custom__value">
											<input class="select-custom__input" type="radio" id="lowest-price" value="lowest-price" name="option-sort"/>
											<p class="select-custom__input-text sort">Lowest Price</p>
										</div>
										<div class="select-custom__value">
											<input class="select-custom__input" type="radio" id="highest-price" value="highest-price" name="option-sort"/>
											<p class="select-custom__input-text sort">Highest Price</p>
										</div>
										<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M9.10044 1.2671C9.10311 1.1632 9.06797 1.06117 8.99986 0.975189C8.93174 0.889211 8.83402 0.82356 8.72036 0.787364C8.60671 0.751167 8.48273 0.746224 8.36565 0.77322C8.24856 0.800217 8.14425 0.857812 8.06711 0.937998L5.00918 3.99757L1.95232 0.937998C1.90475 0.882529 1.84437 0.836075 1.77489 0.801545C1.70541 0.767015 1.62834 0.745154 1.54855 0.737329C1.46876 0.729504 1.38796 0.735885 1.31118 0.756072C1.2344 0.77626 1.16328 0.809818 1.10233 0.854646C1.04138 0.899473 0.991941 0.954605 0.957001 1.01659C0.92206 1.07857 0.902435 1.14607 0.89934 1.21486C0.896243 1.28365 0.909704 1.35224 0.938969 1.41635C0.968233 1.48046 1.01262 1.53869 1.06936 1.58741L4.56598 5.09034C4.62075 5.14535 4.68873 5.18952 4.76534 5.21981C4.84196 5.2501 4.92534 5.26579 5.00972 5.26579C5.09409 5.26579 5.17747 5.2501 5.25409 5.21981C5.3307 5.18952 5.39874 5.14535 5.45351 5.09034L8.9535 1.58741C9.0452 1.49892 9.09733 1.38542 9.10058 1.2671L9.10044 1.2671Z"
												fill="black"/>
										</svg>
									</div>
									<ul class="select-custom__list">
										<li><label class="select-custom__option" for="default-sort" aria-hidden="aria-hidden">Sort
											by</label></li>
										<!--                    <li><label class="select-custom__option" for="recently-listed" aria-hidden="aria-hidden">Recently listed</label></li>-->
										<!--                    <li><label class="select-custom__option" for="newest" aria-hidden="aria-hidden">Newest</label></li>-->
										<li><label class="select-custom__option" for="lowest-price" aria-hidden="aria-hidden">Lowest
											Price</label></li>
										<li><label class="select-custom__option" for="highest-price" aria-hidden="aria-hidden">Highest
											Price</label></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="explore-counter" v-if="auctionFinished && !auctionFinished1Year">
						<p>First NFT’s will be minted in</p>
						<Timer :finish="auctionFinished1Year" @auctionFinish="(type) => auctionFinished1Year = type" :date="changeDate('1years')"/>
					</div>
					<div class="explore-nft">
						<div class="nft-item" v-for="(item, index) in transactions" :key="index" :style="item.address ? '' : 'opacity: 0.6'">
							<img :src="require(`../../public/Lae-backup/pics/t${item.id}.png`)" alt="1 YEAR Auction">
							<div class="nft-item__info">
								<div class="nft-item__header">
									<div class="nft-item__title">{{item.name}}</div>
									<div class="nft-item__season" :class="item.attributes[1].value.toLowerCase()">
										{{item.attributes[1].value}}
									</div>
								</div>
								<div class="nft-item__row">
									<div class="nft-item__name" v-if="item.address">bought for</div>
									<div class="nft-item__value" v-if="item.attributes[0].value">{{item.attributes[0].value}}</div>
								</div>
								
								<div class="nft-item__row">
									<div class="nft-item__name" v-if="item.address">winner</div>
									<router-link :to="{ name: 'WalletInfo', params: {address: item.address} }" tag="div" class="nft-item__value cursor-pointer" v-if="item.address">
										{{item.address.substr(0,4)}}...{{item.address.substr(item.address.length - 7)}}
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="pagination">
						<p class="pagination-info">Showing {{transactions.length}} of {{itemsCount()}} Items</p>
						<div class="pagination-progress"><span
							:style="'width:' + ((100 * transactions.length) / itemsCount())  + '%'" class="pagination-current"></span>
						</div>
						<button class="pagination-btn" @click="showMore" id="more">Show 12 more</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
  import Timer from '../components/Timer.vue'
  import moment from "moment"
  import ExploreLoading from "../components/Loading/ExploreLoading";
  import {getTransactionIdsByAddressJson} from "../scripts/arweave";

  export default {
    name: 'Explore',
    components: {ExploreLoading, Timer},
    data() {
      return {
        limit: 12,
        activeTab: 'all',
        activeYear: '',
        auctionFinished1Year: '',
        loading: false,
        auctionFinished: true,
        filter: 'all',
        transactions: {},
      }
    },
    mounted() {
      this.getTransactionsDataJson()
    },
    methods: {
      changeDate(value) {
        if (!value) return ''
        const d = localStorage.getItem(`${value}`);
        this.finishTime(d)
        return d
      },
      finishTime(date) {
        const countdown = moment(date, 'YYYY-MM-DD HH:mm:ss');
        let t = moment(new Date());
        if (countdown.diff(t) && countdown.diff(t) <= 0) {
          this.auctionFinished = false
        }
      },
      itemsCount() {
        let count = 111
        if (this.filter === '1 year') {
          count = 100
        } else if (this.filter === '10 years') {
          count = 10
        } else if (this.filter === '100 years') {
          count = 1
        }
        if (this.activeTab === 'all') {
          count = count * 4
        }
        return count
      },
      changeTab(tab) {
        this.limit = 12
        this.activeTab = tab
        this.getTransactionsDataJson('', true)
      },
      changeFilter(filter) {
        this.limit = 12
        this.filter = filter
        this.getTransactionsDataJson()
      },
      async getTransactionsDataJson() {
        this.transactions = await getTransactionIdsByAddressJson(this.filter, this.activeTab, this.limit)
        console.log(this.transactions)
      },
      showMore() {
        if (this.transactions.length < this.itemsCount()) {
          this.limit += 12
          this.getTransactionsDataJson()
        }
      }
    }
  }
</script>
<style scoped lang="scss">
	.cursor-pointer {
		cursor: pointer;
	}
	
	.header {
		&-info {
			.wrapper {
				text-align: center;
			}
			
			&.explore {
				padding: 7px 0;
				font-size: 18px;
				line-height: 21px;
				color: #676767;
				background: #F7F7F7;
				
				.wrapper {
					justify-content: center;
				}
				
				@media (max-width: 576px) {
					font-size: 14px;
					line-height: 16px;
				}
			}
		}
	}
	
	.explore {
		&:not(.header-info) {
			margin-bottom: 75px;
		}
		
		.wrapper {
			flex-direction: column;
		}
		
		&-image {
			width: 100%;
			height: 175px;
			object-fit: cover;
			@media (max-width: 992px) {
				height: 135px;
			}
		}
		
		&-header {
			margin: 32px 0 24px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			@media (max-width: 992px) {
				margin: 25px 0 14px;
			}
			
			&__title {
				font-weight: bold;
				font-size: 45px;
				line-height: 55px;
				color: #000000;
				@media (max-width: 992px) {
					font-size: 30px;
					line-height: 36px;
				}
			}
			
			&__minted {
				display: flex;
				align-items: center;
				font-weight: bold;
				font-size: 22px;
				line-height: 26px;
				color: #000000;
				@media (max-width: 992px) {
					font-size: 20px;
				}
			}
			
			&__counter {
				margin-left: 12px;
				@media (max-width: 992px) {
					margin-left: 8px;
				}
				
				span:last-child {
					color: #b6b6b6;
				}
			}
		}
		
		&-params {
			width: 100%;
			border-top: 1px solid #DFDFDF;
			border-bottom: 1px solid #DFDFDF;
			padding: 18px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media (max-width: 992px) {
				padding: 12px 0;
			}
			
			&__tabs {
				display: flex;
			}
			
			&__tab {
				padding: 7px 18px;
				background: #F8F8F8;
				border-radius: 20px;
				font-size: 18px;
				line-height: 26px;
				color: #000000;
				border: 1px solid transparent;
				transition: .35s;
				cursor: pointer;
				@media (max-width: 1025px) {
					display: none;
				}
				
				&:hover:not(.active) {
					background: #F0F0F0;
				}
				
				&.active {
					border: 1px solid rgba(237, 125, 55, 0.5);
					color: #ED7D37;
				}
				
				&:not(:last-child) {
					margin-right: 16px;
				}
			}
			
			&__right {
				display: flex;
			}
		}
		
		&-counter {
			margin: 30px 0 18px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			
			p {
				font-size: 24px;
				line-height: 26px;
				color: #000000;
				margin-bottom: 20px;
			}
		}
		
		&-nft {
			margin-top: 18px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 45px;
			width: 100%;
			@media (max-width: 992px) {
				grid-template-columns: 340px 340px;
				grid-gap: 18px;
				justify-content: center;
			}
			@media (max-width: 768px) {
				grid-template-columns: 370px;
			}
			@media (max-width: 390px) {
				grid-template-columns: 1fr;
			}
		}
	}
	
	.nft-item {
		background: #FFFFFF;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		overflow: hidden;
		
		img {
			width: 100%;
			height: 305px;
			object-fit: contain;
			background: #7D888A;
			@media (max-width: 576px) {
				height: 290px;
			}
		}
		
		&__info {
			padding: 20px 18px;
			font-size: 18px;
			line-height: 26px;
			color: #000000;
			@media (max-width: 768px) {
				padding: 16px;
			}
		}
		
		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		
		&__season {
			padding: 4px 16px;
			border-radius: 10px;
			
			&.autumn {
				color: #ED7D37;
				background: rgba(237, 125, 55, 0.1);
			}
			
			&.spring {
				color: #B6DA06;
				background: rgba(182, 218, 6, 0.1);
			}
			
			&.winter {
				color: #086DF9;
				background: rgba(8, 109, 249, 0.1);
			}
			
			&.summer {
				color: #22FF00;
				background: rgba(34, 255, 0, 0.1);
			}
		}
		
		&__title {
			font-weight: bold;
		}
		
		&__row {
			margin-top: 16px;
			display: flex;
			align-items: center;
			@media (max-width: 768px) {
				margin-top: 12px;
			}
		}
		
		&__name {
			flex-basis: 85px;
			margin-right: 12px;
		}
		
		&__value {
			font-weight: bold;
		}
	}
	
	.pagination {
		margin-top: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (max-width: 768px) {
			margin-top: 20px;
		}
		
		&-info {
			margin-bottom: 28px;
			font-size: 20px;
			line-height: 23px;
			color: #040404;
		}
		
		&-progress {
			width: 300px;
			height: 5px;
			border-radius: 2.5px;
			background: #EAEAEA;
			position: relative;
		}
		
		&-current {
			transition: .35s;
			border-radius: 2.5px 0 0 2.5px;
			background: #ED7D37;
			height: 5px;
			width: 50px;
			position: absolute;
			top: 0;
			left: 0;
			
			&:after {
				content: '';
				position: absolute;
				top: -6px;
				right: 0;
				width: 2px;
				height: 6px;
				background: #ED7D37;
			}
		}
		
		&-btn {
			margin-top: 22px;
			padding: 12px 42px;
			font-weight: bold;
			font-size: 20px;
			line-height: 23px;
			color: #FFFFFF;
			background: #333333;
			border-radius: 5px;
			
			&:hover {
				background: #000000;
			}
		}
	}
	
	.select-dbox {
		z-index: 100;
		position: relative;
		display: none;
		width: 130px;
		font-size: 20px;
		line-height: 28px;
		color: #000000;
		transition: .35s;
		@media (max-width: 1025px) {
			display: block;
		}
		
		&:hover {
			.select-dbox__input-text {
				border: 1px solid rgba(237, 125, 55, 0.5);
				color: #ED7D37;
				
				&:before {
					background: #ED7D37;
				}
			}
			
			svg path {
				fill: #ED7D37;
			}
		}
		
		&__current {
			position: relative;
			border-radius: 20px;
			cursor: pointer;
			outline: none;
			
			&:focus {
				& + .select-dbox__list {
					opacity: 1;
					visibility: visible;
					
					.select-dbox__option {
						cursor: pointer;
					}
				}
				
				svg {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
		
		svg {
			position: absolute;
			top: 50%;
			right: 18px;
			transform: translateY(-50%);
			width: 12px;
			transition: .35s;
		}
		
		&__value {
			display: flex;
		}
		
		&__input {
			display: none;
			
			&:checked + .select-dbox__input-text {
				display: flex;
			}
		}
		
		&__input-text {
			white-space: nowrap;
			border: 1px solid transparent;
			width: 100%;
			//opacity: 0;
			//visibility: hidden;
			display: none;
			align-items: center;
			margin: 0;
			padding: 6px 38px 6px 18px;
			background: #F8F8F8;
			border-radius: 20px;
			transition: .35s;
			position: relative;
		}
		
		&__list {
			margin-top: 8px;
			position: absolute;
			right: 0;
			width: 100%;
			padding: 0;
			visibility: hidden;
			list-style: none;
			opacity: 0;
			background: #FFFFFF;
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
			border-radius: 10px;
			padding: 6px;
			overflow: hidden;
			transition: .35s;
		}
		
		&__option {
			display: block;
			padding: 6px 10px;
			background-color: #fff;
			border-radius: 7px;
			transition: .35s;
			font-size: 18px;
			line-height: 26px;
			white-space: nowrap;
			
			&:hover,
			&:focus,
			&:active {
				background: #F0F0F0;
			}
		}
	}
	
	.select-box {
		z-index: 100;
		position: relative;
		display: block;
		width: 170px;
		font-size: 20px;
		line-height: 28px;
		color: #000000;
		transition: .35s;
		@media (max-width: 768px) {
			width: 80px;
		}
		
		&:hover {
			.select-box__input-text {
				border: 1px solid rgba(237, 125, 55, 0.5);
				color: #ED7D37;
				
				&:before {
					background: #ED7D37;
				}
			}
			
			svg path {
				fill: #ED7D37;
			}
		}
		
		&__current {
			position: relative;
			border-radius: 20px;
			cursor: pointer;
			outline: none;
			
			&:focus {
				& + .select-box__list {
					opacity: 1;
					visibility: visible;
					
					.select-box__option {
						cursor: pointer;
					}
				}
				
				svg {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
		
		svg {
			position: absolute;
			top: 50%;
			right: 18px;
			transform: translateY(-50%);
			width: 12px;
			transition: .35s;
		}
		
		&__value {
			display: flex;
		}
		
		&__input {
			display: none;
			
			&:checked + .select-box__input-text {
				display: flex;
			}
		}
		
		&__input-text {
			@media (max-width: 768px) {
				font-size: 0;
			}
			white-space: nowrap;
			border: 1px solid transparent;
			width: 100%;
			//opacity: 0;
			//visibility: hidden;
			display: none;
			align-items: center;
			margin: 0;
			padding: 6px 38px 6px 42px;
			background: #F8F8F8;
			border-radius: 20px;
			transition: .35s;
			position: relative;
			
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 18px;
				min-width: 17px;
				height: 20px;
				margin-right: 8px;
				background: #B6B6B6;
				-webkit-mask: url('../../public/filter.svg') no-repeat center center;
				mask: url('../../public/filter.svg') no-repeat center center;
				-webkit-mask-size: contain;
				mask-size: contain;
				transition: .35s;
			}
		}
		
		&__list {
			margin-top: 8px;
			position: absolute;
			right: 0;
			width: 100%;
			padding: 0;
			visibility: hidden;
			list-style: none;
			opacity: 0;
			background: #FFFFFF;
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
			border-radius: 10px;
			padding: 6px;
			overflow: hidden;
			transition: .35s;
			@media (max-width: 768px) {
				width: 170px;
			}
			
			li:first-child {
				display: none;
			}
		}
		
		&__option {
			display: block;
			padding: 6px 10px;
			background-color: #fff;
			border-radius: 7px;
			transition: .35s;
			font-size: 18px;
			line-height: 26px;
			white-space: nowrap;
			
			&:hover,
			&:focus,
			&:active {
				background: #F0F0F0;
			}
		}
	}
	
	.select-custom {
		z-index: 99;
		position: relative;
		display: block;
		margin-left: 20px;
		width: 210px;
		font-size: 20px;
		line-height: 28px;
		color: #000000;
		transition: .35s;
		@media (max-width: 768px) {
			width: 80px;
		}
		
		&:hover {
			.select-custom__input-text {
				border: 1px solid rgba(237, 125, 55, 0.5);
				color: #ED7D37;
				
				&:before {
					background: #ED7D37;
				}
			}
			
			svg path {
				fill: #ED7D37;
			}
		}
		
		&__current {
			position: relative;
			border-radius: 20px;
			cursor: pointer;
			outline: none;
			
			&:focus {
				& + .select-custom__list {
					opacity: 1;
					visibility: visible;
					
					.select-custom__option {
						cursor: pointer;
					}
				}
				
				svg {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
		
		svg {
			position: absolute;
			top: 50%;
			right: 18px;
			transform: translateY(-50%);
			width: 12px;
			transition: .35s;
		}
		
		&__value {
			display: flex;
		}
		
		&__input {
			display: none;
			
			&:checked + .select-custom__input-text {
				display: flex;
			}
		}
		
		&__input-text {
			white-space: nowrap;
			border: 1px solid transparent;
			width: 100%;
			//opacity: 0;
			//visibility: hidden;
			display: none;
			align-items: center;
			margin: 0;
			padding: 6px 38px 6px 42px;
			background: #F8F8F8;
			border-radius: 20px;
			transition: .35s;
			position: relative;
			@media (max-width: 768px) {
				font-size: 0;
			}
			
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 18px;
				min-width: 17px;
				height: 20px;
				margin-right: 8px;
				background: #B6B6B6;
				-webkit-mask: url('../../public/sort.svg') no-repeat center center;
				mask: url('../../public/sort.svg') no-repeat center center;
				-webkit-mask-size: contain;
				mask-size: contain;
				transition: .35s;
			}
		}
		
		&__list {
			margin-top: 8px;
			position: absolute;
			right: 0;
			width: 100%;
			padding: 0;
			visibility: hidden;
			list-style: none;
			opacity: 0;
			background: #FFFFFF;
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
			border-radius: 10px;
			padding: 6px;
			overflow: hidden;
			transition: .35s;
			
			li:first-child {
				display: none;
			}
			
			@media (max-width: 768px) {
				width: 170px;
			}
		}
		
		&__option {
			display: block;
			padding: 6px 10px;
			background-color: #fff;
			border-radius: 7px;
			transition: .35s;
			font-size: 18px;
			line-height: 26px;
			white-space: nowrap;
			
			&:hover,
			&:focus {
				background: #F0F0F0;
			}
		}
	}
</style>
