var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preloader"
  }, [_c('header-loading'), _c('div', {
    staticClass: "preloader-bg "
  }, [_c('vue-skeleton-loader', {
    staticClass: "mt-6",
    attrs: {
      "type": "rect",
      "width": 438,
      "height": 21,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('vue-skeleton-loader', {
    staticClass: "w-full",
    attrs: {
      "type": "rect",
      "height": 176,
      "animation": "wave"
    }
  })], 1), _c('div', {
    staticClass: "preloader-main"
  }, [_c('div', {
    staticClass: "preloader-main-header"
  }, [_c('vue-skeleton-loader', {
    staticClass: "mt-6",
    attrs: {
      "type": "rect",
      "width": 165,
      "height": 50,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('div', {
    staticClass: "flex"
  }, [_c('vue-skeleton-loader', {
    staticClass: "mr-2",
    attrs: {
      "type": "rect",
      "width": 150,
      "height": 26,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('vue-skeleton-loader', {
    staticClass: "mt-6",
    attrs: {
      "type": "rect",
      "width": 150,
      "height": 26,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  })], 1)], 1), _c('div', {
    staticClass: "flex justify-between w-full mt-37"
  }, [_c('div', {
    staticClass: "flex"
  }, _vm._l(5, function (i) {
    return _c('vue-skeleton-loader', {
      key: i,
      staticClass: "mr-2",
      attrs: {
        "type": "rect",
        "width": 71,
        "height": 41,
        "rounded": true,
        "radius": 1000,
        "animation": "wave"
      }
    });
  }), 1), _c('div', {
    staticClass: "flex"
  }, _vm._l(2, function (i) {
    return _c('vue-skeleton-loader', {
      key: i,
      staticClass: "mr-2",
      attrs: {
        "type": "rect",
        "width": 210,
        "height": 41,
        "rounded": true,
        "radius": 1000,
        "animation": "wave"
      }
    });
  }), 1)]), _c('div', {
    staticClass: "preloader-main-items"
  }, _vm._l(3, function (i) {
    return _c('div', {
      key: i,
      staticClass: "preloader-main-items-item"
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": 350,
        "height": 304,
        "rounded": true,
        "radius": 20,
        "animation": "wave"
      }
    }), _c('vue-skeleton-loader', {
      staticClass: "block",
      attrs: {
        "type": "rect",
        "width": 198,
        "height": 30,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    }), _c('vue-skeleton-loader', {
      staticClass: "block",
      attrs: {
        "type": "rect",
        "width": 160,
        "height": 45,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    })], 1);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }