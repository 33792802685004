<template>
   <span v-show="show" ref="el" :id="property" class="flip-clock__piece">
      <span class="flip-clock__card flip-card">
         <b class="flip-card__top">{{current}}</b>
         <b class="flip-card__bottom" :data-value="current"></b>
         <b class="flip-card__back" :data-value="previous"></b>
         <b class="flip-card__back-bottom" :data-value="previous"></b>
      </span>
      <span class="flip-clock__slot">{{ $t('main.' + property.toLowerCase())}}</span>
  </span>
</template>

<script>
  export default {
    name: "Tracker",
    props: ['property', 'time', 'seconds'],
    data () {
      return {
        current: 0,
        previous: 0,
        show: false
      }
    },
    watch: {
      seconds: {
        handler: function() {
          this.timeUpdate(this.time)
        },
        deep: true
      }
    },
    methods: {
      timeUpdate(newValue) {
        if ( newValue[this.property] === undefined ) {
          this.show = false;
          return;
        }

        var val = newValue[this.property];
        this.show = true;

        val = ( val < 0 ? 0 : val );

        if ( val !== this.current ) {

          this.previous = this.current;
          this.current = val;

          this.$el.classList.remove('flip');
          void this.$el.offsetWidth;
          this.$el.classList.add('flip');
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  #Days {
    .flip-card__top,
    .flip-card__bottom,
    .flip-card__back-bottom,
    .flip-card__back::before,
    .flip-card__back::after {
      width: 61px;
    }
  }

</style>
